<ngx-extended-pdf-viewer *ngIf="pdfDocUrl && !isLoading"
    [src]="pdfDocUrl"
    backgroundColor="#ffffff"
    [height]="'65vh'"
    [handTool]="false"
    [useInlineScripts]="false"
    [showHandToolButton]="true"
    [showToolbar]="toolbar"
    (pdfLoadingFailed)="onEvent()">
</ngx-extended-pdf-viewer>


<div ngClass="box-laoder" *ngIf="isLoading" class="loading-spinner">
    <div class="loader-wrapper">
      <div>
        <app-svg [svgPath]="'../../../../assets/images/loading-progress.svg'"></app-svg>
      </div>
      <div class="loader-wrapper__head-wrap">
        <h4>Appraisal Processing...</h4>
        <p>Reloading in <span>{{count}} secs....</span></p>
      </div>
    </div>
</div>
