import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OrderService } from '../../order.service';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {

  @ViewChild('viewer') viewer: ElementRef;
  @Input() pdfUrl: string;
  @Input() pdfName: string;
  @Input() toolbar: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() viewDocumentOnly = false;
  pdfDocUrl: string;

  count: number = 60;
  private intervalId: any;

  constructor(private orderService: OrderService) {
    this.startCountdown();
    pdfDefaultOptions.enableScripting = false;
    pdfDefaultOptions.annotationMode = 0;
    pdfDefaultOptions.annotationEditorMode = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pdfUrl) {
      this.pdfDocUrl = this.pdfUrl;
    }

    if ('isLoading' in changes) {
      if (!this.isLoading) {
        this.stopCountdown();
      }
    }

  }

  // Start the countdown
  startCountdown() {
    this.intervalId = setInterval(() => {
      this.count--;
      if (this.count === 0) {
        this.stopCountdown();
        this.onCountdownComplete();
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.intervalId);
  }

  onEvent() {
    this.isLoading = true;
    this.startCountdown();
  }

  onCountdownComplete() {
    this.count = 60;
    if (this.pdfName) {
      this.downloadFile(this.pdfName).subscribe(url => {
        this.pdfDocUrl = url;
        this.isLoading = false;
        this.stopCountdown();
      }, err => {
        this.isLoading = true;
        this.startCountdown();
      });
    }
  }

  downloadFile(fileName: string): Observable<string> {
    return this.orderService.downloadReviewDocumnets(fileName).pipe(
      map((data) => data.url)
    );
  }

  setPdf(url: string) {
    if (this.viewDocumentOnly) {
      this.pdfDocUrl = url + '#toolbar&view=FitH&view=FitV';
    } else {
      this.pdfDocUrl = url + '#toolbar=0';
    }
    return this.pdfDocUrl;
  }


  ngOnDestroy() {
    clearInterval(this.intervalId);
  }
}
