<div class="order-review_wrapper">
  <div class="order-review_wrapper__tab-wrapper">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab labelClass="notifications__tab" bodyClass="notifications__tab-content">
        <ng-template mat-tab-label>Appraisal Documents</ng-template>
      </mat-tab>
      <mat-tab labelClass="notifications__tab" bodyClass="notifications__tab-content">
        <ng-template mat-tab-label>Additional Documents</ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="order-review_wrapper__btn-wrap" *ngIf="selectedTab == 1 && !isUploadDocument">
    <button type="button" class="button-outline"(click)="onUploadDocs()" [disabled]="isPreview">
      <mat-icon>add</mat-icon>
      Upload
    </button>

    <button *ngIf="selectedTab == 1 && data?.source === 'integration'"
      [ngClass]="{'btn-outline-disabled': fetchingDocs}" [disabled]="fetchingDocs || isPreview" (click)="fetchDocuments()"
      class="button-outline">
      <app-svg *ngIf="!fetchingDocs" [svgPath]="'../../../../assets/images/fetch.svg'"></app-svg>
      <mat-spinner [ngClass]="{'btn-outline-disabled': fetchingDocs}" class="button-outline__icon" *ngIf="fetchingDocs"
        style="width: 20px; height: 20px;"></mat-spinner>
      {{fetchingDocs ? 'Fetching...' : 'Fetch'}}
    </button>
  </div>
</div>


<form [formGroup]="uploadDocumentForm" *ngIf="selectedTab == 1 && isUploadDocument">
  <div class="order-review_wrapper__upload-document-wrapper">
    <div mat-dialog-content>
      <div class="documents__card">
        <div class="documents__card-body custom-card">
          <div class="documents__file-input">
            <label for="uploadfile">
              <div class="rounded-icon">
                <app-svg [svgPath]="'../../../../assets/images/upload.svg'"></app-svg>
              </div>
              <div class="uplaod-main">
                <div class="uplaod-main__description">
                  <span class="heading" *ngIf="!file_store?.length">Upload File</span>
                  <span class="text" *ngIf="!file_store?.length">{{fileSizeInMB}}MB max file size</span>
                  <span class="text"> <i> {{file_store ? file_store[0]?.name : ''}}</i></span>
                  <input type="file" hidden #f_input (change)="handleFileInputChange(f_input.files)" id="uploadfile"
                    accept=".jpeg,.jpg,.txt,.pdf,.xls,.xlsx,.doc,.docx,.gif,.png,.tif,.tiff" />
                </div>
                <div>
                  <p class="order-review_wrapper__caption"><b>Supported Formats: </b>{{allowedTypes.join(', ')}}</p>
                </div>
              </div>
            </label>
          </div>
          <div class="error-state">
            <span class="text--red">{{fileSizeCheck ? 'File Size Limit Reached' : ''}}</span>
            <span class="text--red">{{fileTypeCheck ? 'Invalid file type' : ''}}</span>
          </div>
          <div class="documents__input-wrap">
            <mat-form-field appearance="outline" subscriptSizing="dynamic">
              <mat-label>Select Document type</mat-label>
              <input matInput [matAutocomplete]="autoState" placeholder="Document type" autocomplete="off"
                formControlName="type" (ngModelChange)="filterOptionsDocType(uploadDocumentForm.get('type')?.value)">
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete #autoState="matAutocomplete">
                <mat-option *ngFor="let typeOption of filteredDocTypes" [value]="typeOption">
                  <span>{{ typeOption }}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="error-state" *ngIf="uploadDocumentForm.get('type')?.hasError('invalidDocumentType')">
              <span class="text--red">Entered document type is not found.</span>
            </div>
          </div>
          <div class="documents__button-wrapper">
              <button type="button" class="button-outline" (click)="onCancelAdditionalDocument()">
                Cancel</button>
              <button type="button" class="order-review_wrapper__btn-filled"
                [disabled]="!uploadDocumentForm.valid || fileTypeCheck || fileSizeCheck"
                (click)="onAddAdditionalRecord(uploadDocumentForm.value)">
                Upload</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div mat-dialog-content>
  <div class="documents__card">
    <div class="back-btn" *ngIf="selectedTab === 0 && isarchived" (click)="toggleArchivedDisplay()" >
      <app-svg [svgPath]="'../../../../assets/images/arrow-left.svg'"></app-svg>
      <div>Archives</div>
    </div>
    <div class="documents__card-body">
      <table mat-table [dataSource]="dataSource" aria-label="documents" *ngIf="selectedTab === 0">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef id="name"> Name </th>
          <td mat-cell *matCellDef="let element" [title]="element.name"> {{element.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef id="type"> Type </th>
          <td mat-cell *matCellDef="let element"> {{(!element?.doc_type?.length ?
            'Other' : element.doc_type)}} </td>
        </ng-container>

        <!-- fileSize Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef id="created_at"> Date Added </th>
          <td mat-cell *matCellDef="let element">
            <div class="date-text">
              {{element?.created_on ? (element.created_on | date: 'M/d/yy') : ''}}
            </div>
            <div class="text-center download-wrapper">
              <img src="../../../../../assets/images/download-dark.svg" alt="" (click)="downloadFile(element.name)">
            </div>
          </td>
        </ng-container>

        <!-- fileSize Column -->
        <ng-container matColumnDef="date_loaded">
          <th mat-header-cell *matHeaderCellDef id="date_loaded"> Date Added </th>
          <td mat-cell *matCellDef="let element">
            <div class="date-text">
              {{element?.date_loaded ? (element.date_loaded | date: 'M/d/yy') : ''}}
            </div>
            <div class="text-center download-wrapper">
              <img src="../../../../../assets/images/download-dark.svg" alt="" (click)="downloadFile(element.name)">
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <table mat-table [dataSource]="dataSourceAdditional" aria-label="documents" *ngIf="selectedTab === 1">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef id="name"> Name </th>
          <td mat-cell *matCellDef="let element" [title]="selectedTab === 1 && element.display_name ? element.display_name : element.name"> {{selectedTab === 1 && element.display_name ? element.display_name :
            element.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef id="type"> Type </th>
          <td mat-cell *matCellDef="let element"> {{selectedTab === 1 ? element.type : (!element?.doc_type?.length ?
            'Other' : element.doc_type)}} </td>
        </ng-container>

        <!-- fileSize Column -->
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef id="created_at"> Date Added </th>
          <td mat-cell *matCellDef="let element">
            <div class="date-text">
              {{element?.created_on ? (element.created_on | date: 'M/d/yy') : ''}}
            </div>
            <div class="text-center download-wrapper">
              <img src="../../../../../assets/images/download-dark.svg" alt="" (click)="downloadFile(element.name)">
            </div>
          </td>
        </ng-container>

        <!-- fileSize Column -->
        <ng-container matColumnDef="date_loaded">
          <th mat-header-cell *matHeaderCellDef id="date_loaded"> Date Added </th>
          <td mat-cell *matCellDef="let element">
            <div class="date-text">
              {{element?.date_loaded ? (element.date_loaded | date: 'M/d/yy') : ''}}
            </div>
            <div class="text-center download-wrapper">
              <img src="../../../../../assets/images/download-dark.svg" alt="" (click)="downloadFile(element.name)">
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
    <div *ngIf="selectedTab === 0 && dataSource?.length === 0" class="no-records">No Documents are Available</div>
    <div *ngIf="selectedTab === 1 && dataSourceAdditional?.length === 0" class="no-records">No Documents are Available</div>
    <div *ngIf="selectedTab === 0 && !isarchived" (click)="toggleArchivedDisplay()">
      <button class="documents__card button-outline">
        <app-svg [svgPath]="'../../../../assets/images/archive.svg'"></app-svg>View Archived</button>
    </div>
  </div>

  <div mat-dialog-actions class="documents__card-footer">
  </div>

</div>
