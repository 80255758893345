<div class="main-order-review">
  <div class="main-order-review__row">
    <div class="main-order-review__right">
      <div class="breadcrumbs" *ngIf="!token">
        <a routerLink="/"><img src="../../../assets/images/home-2.svg" alt=""></a> /
        <a routerLink="../..">Orders</a> /
        <p>Order preview [{{orderNo}}]</p>
      </div>
      <app-order-review-header [page]="'preview'"
      [orderNo]="orderNo"
      [orderStatus]="orderStatus"
      [revisionNumber]="revisionNumber"
      ></app-order-review-header>
      <app-pdf-viewer [pdfUrl]="pdfUrl" [isLoading]="isLoading" [toolbar]="true"
      [pdfName]="pdfName" [viewDocumentOnly]="viewDocumentOnly"></app-pdf-viewer>
    </div>
  </div>
</div>
